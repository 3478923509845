<template>
  <div class="connect-calendar">
    <h2>{{ $t('components.connectCalendar.title') }}</h2>
    <p>{{ $t('components.connectCalendar.description') }}</p>

    <div v-if="isLoading" class="loading">
      <ProgressSpinner />
    </div>

    <template v-else>
      <Button
        v-if="!isConnected"
        :label="$t('components.connectCalendar.connectButton')"
        icon="pi pi-calendar"
        @click="handleConnectCalendar"
        class="p-button-primary"
      />
      <Button
        v-else
        :label="$t('components.connectCalendar.disconnectButton')"
        icon="pi pi-calendar-times"
        @click="handleDisconnectCalendar"
        class="p-button-danger"
      />
    </template>

    <small v-if="error" class="error-message">{{ error }}</small>
  </div>
</template>

<script>
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { getAuth } from 'firebase/auth'
import Button from 'primevue/button'
import ProgressSpinner from 'primevue/progressspinner'
import { PKCEUtils } from '@/utils/PKCE'

export default {
  name: 'ConnectCalendar',

  components: {
    Button,
    ProgressSpinner
  },

  setup() {
    const store = useStore()
    const auth = getAuth()

    const isConnected = computed(() => {
      console.log('Calendar connection state:', store.state.calendar?.isConnected)
      return store.state.calendar?.isConnected
    })
    const isLoading = computed(() => store.state.calendar.isLoading)
    const error = computed(() => store.state.calendar.error)

    const handleConnectCalendar = async () => {
      try {
        const codeVerifier = PKCEUtils.generateRandomString(128)
        localStorage.setItem('codeVerifier', codeVerifier)
        const codeChallenge = await PKCEUtils.generateCodeChallenge(codeVerifier)

        const authUrl = new URL('https://accounts.google.com/o/oauth2/v2/auth')
        authUrl.searchParams.set('client_id', process.env.VUE_APP_GOOGLE_CLIENT_ID)
        authUrl.searchParams.set('redirect_uri', process.env.VUE_APP_GOOGLE_REDIRECT_URI)
        authUrl.searchParams.set('response_type', 'code')
        authUrl.searchParams.set('scope', [
          'https://www.googleapis.com/auth/calendar.events',
          'https://www.googleapis.com/auth/calendar.readonly'
        ].join(' '))
        authUrl.searchParams.set('access_type', 'offline')
        authUrl.searchParams.set('prompt', 'consent')
        authUrl.searchParams.set('code_challenge', codeChallenge)
        authUrl.searchParams.set('code_challenge_method', 'S256')
        authUrl.searchParams.set('include_granted_scopes', 'true')

        window.location.href = authUrl.toString()
      } catch (error) {
        console.error('Calendar connection error:', error)
      }
    }

    const handleDisconnectCalendar = async () => {
      try {
        await store.dispatch('calendar/disconnectCalendar', auth.currentUser)
      } catch (error) {
        console.error('Calendar disconnection error:', error)
      }
    }

    onMounted(async () => {
      if (auth.currentUser) {
        console.log('Checking calendar connection...')
        await store.dispatch('calendar/checkCalendarConnection', auth.currentUser)
        console.log('Calendar connection checked, state:', store.state.calendar?.isConnected)
      }
    })

    return {
      isConnected,
      isLoading,
      error,
      handleConnectCalendar,
      handleDisconnectCalendar
    }
  }
}
</script>

<style lang="less" scoped>
.connect-calendar {
  text-align: center;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

h2 {
  color: var(--primary-color);
  margin-bottom: 1rem;
}

p {
  margin-bottom: 1.5rem;
  color: #666;
}

.loading {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}

.error-message {
  color: var(--red-500);
  margin-top: 0.5rem;
  display: block;
}
</style> 