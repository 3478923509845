<script>
import Sign from '@/components/templates/sign/Sign.vue'
import Signup from '@/components/templates/sign/Signup.vue'
import { useI18n } from 'vue-i18n'

export default {
  components: {
    Sign,
    Signup
  },
  setup() {
    const { t } = useI18n()
    return { t }
  }
}
</script>

<template>
  <Sign>
    <template #title>
      {{ t('views.sign.signup.titleText') }}
    </template>
    
    <template #content>
      <Signup />
    </template>

    <template #signin-signup-alternative>
      <div class="text-center">
        <!-- Terms and Privacy Policy text -->
        <div class="terms-privacy mt-3">
          {{ t('components.templates.sign.signup.temsAndPrivacyPolicyStatement.firstStatement') }}
          <a :href="t('components.templates.sign.signup.temsAndPrivacyPolicyStatement.terms.link')" class="link">
            {{ t('components.templates.sign.signup.temsAndPrivacyPolicyStatement.terms.text') }}
          </a>
          {{ t('components.templates.sign.signup.temsAndPrivacyPolicyStatement.connective') }}
          <a :href="t('components.templates.sign.signup.temsAndPrivacyPolicyStatement.privacy.link')" class="link">
            {{ t('components.templates.sign.signup.temsAndPrivacyPolicyStatement.privacy.text') }}
          </a>
        </div>

        <div class="mt-3">
          <span class="companion-text">{{ t('components.templates.sign.signup.signinLinkCompanionText') }}</span>
          <span class="companion-text">&nbsp;</span>
          <a href="/signin" class="link">{{ t('components.templates.sign.signup.signinLinkLabel') }}</a>
        </div>
      </div>
    </template>
  </Sign>
</template>

<style scoped lang="less">
@import '../../assets/style/colors';

.terms-privacy {
  font-size: 0.9em;
  color: #666;
  
  .link {
    text-decoration: none;
    color: @mrcall_blue;
    
    &:hover {
      text-decoration: underline;
    }
  }
}

.mt-3 {
  margin-top: 1rem;
}
</style>