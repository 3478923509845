// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/mrcall/icons/warning_modal_dialog.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../assets/images/mrcall/icons/error_modal_dialog.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../assets/images/mrcall/icons/success_modal_dialog.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../../assets/images/mrcall/icons/make_a_test_call.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../../assets/images/mrcall/icons/phone_icon.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* PRINCIPALS */
/* SECONDARY */
/* ACCESSORY */
/* TEMPLATES */
.inputboxtitle[data-v-2b8ace6e] {
  /* body/semibold-600/line-height-auto */
  font-family: 'Inter', serif, 'primeicons';
  font-style: normal;
  font-weight: 600;
  font-size: 1em;
  line-height: 1em;
  /* identical to box height */
  /* Lara/Global/textColor */
  padding: 1em 0 0.4em 0;
  color: #495057;
}
.inputboxsubtitle[data-v-2b8ace6e] {
  font-family: 'Inter', serif, 'primeicons';
  font-style: normal;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 1em;
  /* identical to box height */
  /* Lara/Global/textColor */
  padding: 0.4em 0 0 0;
  color: #495057;
}
.inputboxsubtitle[data-v-2b8ace6e] a:link {
  color: #0068FF;
  background-color: transparent;
  text-decoration: none;
  font-weight: 600;
  font-size: 1em;
}
.inputboxsubtitle[data-v-2b8ace6e] a:visited {
  color: #0068FF;
  background-color: transparent;
  text-decoration: none;
  font-weight: 600;
  font-size: 1em;
}
.inputboxsubtitle[data-v-2b8ace6e] a:hover {
  color: #0F110C;
  background-color: transparent;
  text-decoration: underline;
  font-weight: 600;
  font-size: 1em;
}
.inputboxsubtitle[data-v-2b8ace6e] a:active {
  color: #0068FF;
  background-color: transparent;
  text-decoration: none;
  font-weight: 600;
  font-size: 1em;
}
.inputboxerror[data-v-2b8ace6e] {
  font-family: 'Inter', serif, 'primeicons';
  font-style: normal;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 1em;
  /* identical to box height */
  /* Lara/Global/textColor */
  padding: 0.4em 0 0 0;
  color: red;
}
.item[data-v-2b8ace6e] {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: auto;
  padding-top: 2em;
  padding-bottom: 2em;
  --maz-font-family: 'Inter', serif, 'primeicons';
  --maz-border-radius: 3px;
  --maz-border-width: 1px;
}
@media screen and (max-width: 640px) {
.item[data-v-2b8ace6e] {
    height: 100%;
}
}
@media screen and (min-width: 640px) {
.item[data-v-2b8ace6e] {
    height: 100%;
}
}
.item .p-component[data-v-2b8ace6e] {
  width: 100%;
}
#footer[data-v-2b8ace6e] {
  display: flex;
}
@media screen and (max-width: 640px) {
#footer[data-v-2b8ace6e] {
    flex-direction: column;
}
}
@media screen and (min-width: 640px) {
#footer[data-v-2b8ace6e] {
    flex-direction: row;
}
}
#footer .p-button[data-v-2b8ace6e] {
  font-family: 'Inter', serif, 'primeicons';
  font-style: normal;
  font-weight: 700;
  font-size: 1em;
  line-height: 1em;
  margin: 0.2em 0.2em 0.2em auto;
}
@media screen and (min-width: 640px) {
#footer .button-left[data-v-2b8ace6e] {
    margin-right: auto;
    padding-right: 1em;
}
}
#footer .button-left .p-button-left.p-button[data-v-2b8ace6e] {
  font-family: 'Inter', serif, 'primeicons';
  font-style: normal;
  font-weight: 700;
  font-size: 1em;
  line-height: 1em;
  color: #6C757D;
  background: transparent;
  border: 1px solid #6C757D;
  padding: 0.5rem 1em;
  margin: 0.2em 0.2em 0.2em auto;
  border-radius: 33px;
}
#footer .button-left .p-button-left.p-button[data-v-2b8ace6e]:hover {
  background: #6C757D;
  color: #FFFFFF;
}
.modal_dialog_content[data-v-2b8ace6e] {
  display: flex;
  flex-direction: column;
  margin: 0 auto 0 auto;
}
@media screen and (max-width: 640px) {
.modal_dialog_content[data-v-2b8ace6e] {
    width: 100%;
}
}
@media screen and (min-width: 640px) {
.modal_dialog_content[data-v-2b8ace6e] {
    width: 450px;
}
}
.modal_dialog_content .warning-icon[data-v-2b8ace6e] {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat;
  width: 5em;
  height: 5em;
  margin: 0 auto 0 auto;
}
.modal_dialog_content .error-icon[data-v-2b8ace6e] {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) no-repeat;
  width: 5em;
  height: 5em;
  margin: 0 auto 0 auto;
}
.modal_dialog_content .success-icon[data-v-2b8ace6e] {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) no-repeat;
  width: 5em;
  height: 5em;
  margin: 0 auto 0 auto;
}
.modal_dialog_content .modal_dialog_title[data-v-2b8ace6e] {
  margin: 0 auto 0 auto;
  text-align: center;
  /* body/semibold-600/line-height-auto */
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 2em;
  line-height: 1em;
  /* identical to box height */
  /* Lara/Global/textColor */
  color: #495057;
}
.modal_dialog_content .modal_dialog_subtitle[data-v-2b8ace6e] {
  margin: 0 auto 0 auto;
  text-align: center;
  /* body/semibold-600/line-height-auto */
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 600;
  padding-top: 1em;
  font-size: 1em;
  line-height: 1em;
  /* identical to box height */
  /* Lara/Global/textColor */
  color: #495057;
}
.modal_dialog_content .modal_dialog_message[data-v-2b8ace6e] {
  padding: 3em 1em 1em 1em;
  margin: 0 auto 0 auto;
  /* body/semibold-600/line-height-auto */
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1em;
  line-height: 1em;
  /* identical to box height */
  /* Lara/Global/textColor */
  color: #495057;
}
.modal-dialog-footer[data-v-2b8ace6e] {
  display: flex;
}
@media screen and (max-width: 640px) {
.modal-dialog-footer[data-v-2b8ace6e] {
    flex-direction: column;
}
}
@media screen and (min-width: 640px) {
.modal-dialog-footer[data-v-2b8ace6e] {
    flex-direction: row;
}
}
.modal-dialog-footer .p-button[data-v-2b8ace6e] {
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1em;
  line-height: 1em;
}
@media screen and (min-width: 640px) {
.modal-dialog-footer .button-left[data-v-2b8ace6e] {
    margin-right: auto;
    padding-right: 1em;
}
}
.modal-dialog-footer .button-left .p-button-left.p-button[data-v-2b8ace6e] {
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1em;
  line-height: 1em;
  color: #6C757D;
  background: transparent;
  border: 1px solid #6C757D;
  padding: 0.5rem 1em;
  margin: 0.2em 0.2em 0.2em auto;
  border-radius: 33px;
}
.modal-dialog-footer .button-left .p-button-left.p-button[data-v-2b8ace6e]:hover {
  background: #6C757D;
  color: #FFFFFF;
}
#makeatestcall-icon[data-v-2b8ace6e] {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) no-repeat;
  width: 5em;
  height: 5em;
  margin: 0 auto 0 auto;
}
.phone-number-box[data-v-2b8ace6e] {
  display: flex;
  flex-direction: row;
  background: #E5E5E5;
  margin: auto;
  padding: 1em;
}
.phone-number-box .phone-icon[data-v-2b8ace6e] {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) no-repeat;
  width: 1.5em;
  height: 1.5em;
  margin: 0 1em 0 auto;
}
.phone-number-box .phone-number[data-v-2b8ace6e] {
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.5em;
  line-height: 1em;
}
.inputboxtitle[data-v-2b8ace6e] {
  margin: 0 auto 0 auto;
}
.inputboxsubtitle[data-v-2b8ace6e] {
  margin: 0 auto 0 auto;
}
.modal_dialog_no_testcall_title[data-v-2b8ace6e] {
  margin: 0 0 1em 0 ;
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.1em;
}
ul[data-v-2b8ace6e] {
  display: block;
}
ul li[data-v-2b8ace6e] {
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.8em;
  margin: 1em 0 1em 0;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
