import { OAuthHelper } from '@/utils/OAuth'

export default {
  namespaced: true,
  
  state: {
    isConnected: false,
    isLoading: false,
    error: null
  },

  mutations: {
    SET_CONNECTED(state, value) {
      state.isConnected = value
    },
    SET_LOADING(state, value) {
      state.isLoading = value
    },
    SET_ERROR(state, error) {
      state.error = error
    }
  },

  actions: {
    async checkCalendarConnection({ commit }, user) {
      try {
        commit('SET_LOADING', true)
        const credentials = await OAuthHelper.getGoogleCalendarCredentials(user)
        commit('SET_CONNECTED', !!credentials && !OAuthHelper.isTokenExpired(credentials))
      } catch (error) {
        console.error('Failed to check calendar connection:', error)
        commit('SET_ERROR', error.message)
      } finally {
        commit('SET_LOADING', false)
      }
    },

    async disconnectCalendar({ commit }, user) {
      try {
        commit('SET_LOADING', true)
        await OAuthHelper.updateGoogleCalendarCredentials(user, null)
        commit('SET_CONNECTED', false)
      } catch (error) {
        console.error('Failed to disconnect calendar:', error)
        commit('SET_ERROR', error.message)
      } finally {
        commit('SET_LOADING', false)
      }
    }
  }
} 