<script setup>
import Sign from "@/components/templates/sign/Sign";
import Signup from "@/components/templates/sign/Signup";
</script>

<template>
  <Sign>
    <template #title>
      <div id="title">{{$t('views.sign.signup.titleText')}}</div>
    </template>
    <template #content>
      <Signup></Signup>
    </template>
  </Sign>
</template>

<style scoped lang="less">
@import '../../assets/style/colors';
</style>