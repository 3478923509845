// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* PRINCIPALS */
/* SECONDARY */
/* ACCESSORY */
/* TEMPLATES */
.signin-signup-form[data-v-c72180c4] {
  width: 100%;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
}
.signin-signup-form .title[data-v-c72180c4] {
  font-size: 1.5rem !important;
}
.signin-signup-form .p-button[data-v-c72180c4] {
  width: 100%;
}
.signin-signup-form .p-field[data-v-c72180c4] {
  margin: 1rem 0 1rem 0;
}
.signin-signup-form .inputboxtitle[data-v-c72180c4] {
  /* body/semibold-600/line-height-auto */
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  flex-grow: 1;
  /* identical to box height */
  /* Lara/Global/textColor */
  padding: 1em 0px;
  color: #495057;
}
.signin-signup-form .card[data-v-c72180c4] {
  background: var(--surface-card);
  padding: 2rem;
  border-radius: 10px;
  width: 450px;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  margin: 2rem;
}
.signin-signup-form .form-container[data-v-c72180c4] {
  max-width: 400px;
  margin: 0 auto;
}
.p-tag[data-v-c72180c4] {
  background: #3F413D;
}
#signup-part .p-button[data-v-c72180c4] {
  background: #0F110C;
  border: 1px solid #0F110C;
  font-weight: bold;
  color: #F7941F;
}
#signup-part .p-button[data-v-c72180c4]:hover {
  background: #F7941F;
  color: #0F110C;
}
.account-section[data-v-c72180c4] {
  max-width: 600px;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
}
.logo-container[data-v-c72180c4] {
  text-align: center;
  margin-bottom: 2rem;
}
.logo-container .logo[data-v-c72180c4] {
  width: 200px;
  height: auto;
}
.sign-title[data-v-c72180c4] {
  text-align: center;
  margin-bottom: 2rem;
  color: var(--text-color);
  font-size: 1.5rem;
  font-weight: 600;
}
.divider[data-v-c72180c4] {
  text-align: center;
  margin: 1rem 0;
  position: relative;
}
.divider[data-v-c72180c4]::before,
.divider[data-v-c72180c4]::after {
  content: '';
  position: absolute;
  top: 50%;
  width: 45%;
  height: 1px;
  background-color: var(--surface-border);
}
.divider[data-v-c72180c4]::before {
  left: 0;
}
.divider[data-v-c72180c4]::after {
  right: 0;
}
.divider span[data-v-c72180c4] {
  background-color: var(--surface-card);
  padding: 0 10px;
  color: var(--text-color-secondary);
  font-size: 0.9rem;
}
.form-wrapper[data-v-c72180c4] {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
