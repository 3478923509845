<template>
  <div class="calendar-callback">
    <ProgressSpinner v-if="loading" />
    <div v-else-if="error" class="error-message">
      {{ error }}
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import ProgressSpinner from 'primevue/progressspinner'

export default {
  name: 'GoogleCalendarCallback',
  components: {
    ProgressSpinner
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    
    const loading = ref(true)
    const error = ref(null)

    onMounted(async () => {
      try {
        const code = route.query.code
        if (!code) {
          throw new Error('No authorization code received')
        }

        // Get stored PKCE verifier
        const codeVerifier = localStorage.getItem('codeVerifier')
        if (!codeVerifier) {
          throw new Error('No code verifier found')
        }

        // Exchange code for tokens
        await store.dispatch('connectGoogleCalendar', { 
          code,
          codeVerifier
        })

        // Clear PKCE verifier
        localStorage.removeItem('codeVerifier')
        
        // Redirect back to account page
        router.push('/account')
      } catch (err) {
        console.error('Calendar connection error:', err)
        error.value = 'Failed to connect Google Calendar. Please try again.'
      } finally {
        loading.value = false
      }
    })

    return {
      loading,
      error
    }
  }
}
</script> 