<template>
  <div class="account-section main-page-content-section">
    <div class="content">
      <ChangePassword></ChangePassword>
      <br>
      <br>
      <ChangeEmail></ChangeEmail>
      <br>
      <br>
      <ConnectCalendar></ConnectCalendar>
      <br>
      <br>
      <DeleteAccount></DeleteAccount>
    </div>
  </div>
  <footer id="pre-footer">
    <PreFooterRequireAssistance/>
  </footer>
</template>

<script>
import ChangePassword from "@/components/ChangePassword";
import ChangeEmail from "@/components/ChangeEmail";
import DeleteAccount from "@/components/DeleteAccount";
import ConnectCalendar from "@/components/ConnectCalendar";
import PreFooterRequireAssistance from "@/components/PreFooterRequireAssistance";

export default {
  components: {
    ChangePassword,
    ChangeEmail,
    DeleteAccount,
    ConnectCalendar,
    PreFooterRequireAssistance
  },
  setup() {
  },
  data() {
    const pageTexts = {
      it: {
      }
    }
    return {
      pageTexts
    }
  },
  created() {
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {}
}
</script>

<style lang="less" scoped>
.content {
  max-width: 60%;
  margin: auto;
}
#pre-footer {
  margin-top: auto;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
}
</style>