<template>
  <div class="google-callback">
    <p>{{ isCalendarFlow ? 'Connecting calendar...' : 'Processing sign in...' }}</p>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { getAuth, signInWithCredential, GoogleAuthProvider } from 'firebase/auth'
import axios from 'axios'
import { OAuthHelper } from '@/utils/OAuth'
import store from '@/store'

export default {
  name: 'GoogleCallback',
  
  setup() {
    const router = useRouter()
    const auth = getAuth()
    const isCalendarFlow = ref(false)

    const handleCallback = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search)
        const code = urlParams.get('code')
        const codeVerifier = localStorage.getItem('codeVerifier')

        if (!code || !codeVerifier) {
          throw new Error('Missing authorization code or code verifier')
        }

        // Get token response
        const tokenResponse = await axios.post('https://oauth2.googleapis.com/token', {
          client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
          client_secret: process.env.VUE_APP_GOOGLE_CLIENT_SECRET,
          code,
          code_verifier: codeVerifier,
          grant_type: 'authorization_code',
          redirect_uri: process.env.VUE_APP_GOOGLE_REDIRECT_URI
        })

        const { access_token, refresh_token, expires_in, scope } = tokenResponse.data
        const scopes = scope.split(' ')
        
        // Check if this is a calendar authorization flow
        isCalendarFlow.value = scopes.includes('https://www.googleapis.com/auth/calendar')
        
        if (isCalendarFlow.value) {
          // Wait for auth state to be ready
          const waitForUser = () => {
            return new Promise((resolve) => {
              const unsubscribe = auth.onAuthStateChanged((user) => {
                unsubscribe();
                resolve(user);
              });
            });
          };

          const user = await waitForUser();
          
          if (!user) {
            throw new Error('User not authenticated');
          }

          // Store calendar credentials
          const calendarCredentials = {
            accessToken: access_token,
            refreshToken: refresh_token,
            expiresAt: Date.now() + (expires_in * 1000),
            scope: scopes
          }
          
          await OAuthHelper.updateGoogleCalendarCredentials(user, calendarCredentials)
          await store.dispatch('calendar/checkCalendarConnection', user)
          
          // Redirect back to account page
          router.replace('/account')
        } else {
          // Handle normal sign in flow
          const credential = GoogleAuthProvider.credential(null, access_token)
          const result = await signInWithCredential(auth, credential)
          const user = result.user

          if (result.additionalUserInfo?.isNewUser) {
            window.dataLayer.push({
              'event': 'sign_up',
              'method': 'Google',
              'user_id': user.uid,
              'user_email': user.email,
              'event_category': 'engagement',
              'event_label': 'Google Sign Up',
              'debug_timestamp': new Date().getTime()
            })
          }

          await store.dispatch('updateUser', { user })

          const oauthCredentials = {
            accessToken: access_token,
            refreshToken: refresh_token,
            expiresAt: Date.now() + (expires_in * 1000),
            scope: scopes
          }

          await OAuthHelper.updateGoogleCredentials(user, oauthCredentials)
          await checkUserStatusAndRedirect(user)
        }

      } catch (error) {
        console.error('Error processing callback:', error)
        router.replace(isCalendarFlow.value ? '/account' : '/signin')
      }
    }

    const checkUserStatusAndRedirect = async (user) => {
      try {
        const headers = {
          "Content-type": "application/json; charset=UTF-8",
          "auth": user.accessToken
        }
        const url = process.env.VUE_APP_STARCHAT_URL + "/mrcall/v1/talkmeapp0/crm/business/search"
        const response = await axios.post(url, 
          { offset: 0, limit: 1 },
          { headers }
        )

        if (response.headers["x-mrcall-role"] === "admin") {
          store.commit('setRole', 'admin')
        } else {
          store.commit('setRole', 'owner')
        }
        
        const needsOnboarding = !response.data || response.data.length === 0
        await router.replace(needsOnboarding ? '/onboardinglang' : '/businesses')
      } catch (error) {
        console.error('Error checking user status:', error)
        if (error.response?.status === 401) {
          await store.dispatch('logout')
          router.replace('/signin')
        } else {
          router.replace('/onboardinglang')
        }
      }
    }

    onMounted(() => {
      handleCallback()
    })

    return {
      isCalendarFlow
    }
  }
}
</script> 