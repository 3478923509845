<template>
  <OnboardingBase>
    <template #spinner>
      <ProgressSpinner
          v-show="showSpinner"
          style="position: fixed; top: 50%; transform: translate(0%, -50%); width: 25%; height: 20%; z-index: 100;"
          strokeWidth="4"
          :pt="{
            spinner: { style: { animationDuration: '2s' } },
            circle: { style: { stroke: '#0068FF', strokeWidth: 3, animation: 'auto' } }
          }"
          fill="transparent"
          animationDuration="2.5s" aria-label="ProgressSpinner" />
    </template>
    <template #title>
      <div id="makeatestcall-icon">
      </div>
      {{ $t('views.onboarding.makeatestcall.titleText') }}
    </template>
    <template #subtitle>{{ $t('views.onboarding.makeatestcall.subtitleText') }}: {{testBusinessNumber()}} </template>
    <template #content>
      <Dialog v-model:visible="modalDialogVisible"
              :dismissableMask="false"
              :closable="false"
              modal>
        <div class="modal_dialog_content">
          <div :class="'modal_dialog_icon' + ' ' + modalDialogIconClass">
          </div>
          <div class="modal_dialog_title">
            <div v-for="item in modalDialogTitle" :key="item">
              {{item}}
            </div>
          </div>
          <div v-if="modalDialogSubTitle" class="modal_dialog_subtitle">
            {{modalDialogSubTitle}}
          </div>
          <div class="modal_dialog_message">
            <div v-if="modalDialogBodyMessage"
                 v-for="item in modalDialogBodyMessage" :key="item" style="line-height: 1.5em;">
              {{item}}
            </div>
            <div v-else-if="modalDialogNoTestCallMessage">
              <div class="modal_dialog_no_testcall_title">
                {{$t('views.onboarding.makeatestcall.modalDialogNoTestCallBodyMessageTitle')}}
              </div>
              <ul>
                <li>
                  {{$t('views.onboarding.makeatestcall.modalDialogNoTestCallBodyMessage1')}}: {{onboardingData.business.businessPhoneNumber}}
                </li>
                <li>
                  {{$t('views.onboarding.makeatestcall.modalDialogNoTestCallBodyMessage2_1')}}
                  <a :href="'tel:' + $t('views.onboarding.makeatestcall.modalDialogNoTestCallBodyMessageSupportNumber')"
                     target='_self'>
                    {{$t('views.onboarding.makeatestcall.modalDialogNoTestCallBodyMessageSupportNumber')}}
                  </a>
                  {{$t('views.onboarding.makeatestcall.modalDialogNoTestCallBodyMessage2_2')}}
                  <a href="mailto:support@mrcall.ai">support@mrcall.ai</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <template #footer>
          <div class="modal-dialog-footer">
            <div v-if="modalDialogLeftButtonLabel" class="button-left">
              <Button :label="modalDialogLeftButtonLabel" @click="resetErrorMessage(modalDialogLeftRedirectComponentName)" class="p-button-text md:w-auto py-3 w-full p-button-left"/>
            </div>
            <div v-if="modalDialogRightButtonLabel" class="button-right">
              <Button :label="modalDialogRightButtonLabel" @click="resetErrorMessage(modalDialogRightRedirectComponentName)" class="p-button-text md:w-auto py-3 w-full"/>
            </div>
          </div>
        </template>
      </Dialog>
      <div class="item">
        <div class="inputboxtitle">
          {{$t('views.onboarding.makeatestcall.callYourTestAssistant')}}
        </div>
        <div class="phone-number-box">
          <div class="phone-icon"></div>
          <div class="phone-number">{{testServiceNumber()}}</div>
        </div>
        <div class="inputboxsubtitle">
          {{$t('views.onboarding.makeatestcall.changeNumberCountry')}}
        </div>
      </div>
    </template>
    <template #footer>
      <div class="button-left">
      </div>
      <!--
       <div class="button-left">
         <Button :label="$t('views.onboarding.makeatestcall.moveBackward')"
                 @click="router.push({name: 'OnboardingSimplifiedConfiguration3'})"
                 class="p-button-text md:w-auto py-3 w-full p-button-left"/>
       </div>
       -->
      <!--
      <div class="button-right">
        <Button :label="$t('views.onboarding.makeatestcall.skip')" @click="skipTest()" class="p-button-text md:w-auto py-3 w-full"/>
      </div>
      <div class="button-right">
        <Button :label="$t('views.onboarding.makeatestcall.moveForward')" @click="checkIfTested()" class="p-button-text md:w-auto py-3 w-full"/>
      </div>
      -->
      <div class="button-right">
        <Button :label="$t('views.onboarding.makeatestcall.moveForward')" @click="skipTest()" class="p-button-text md:w-auto py-3 w-full"/>
      </div>
    </template>
  </OnboardingBase>
</template>

<script>
import OnboardingBase from "@/components/templates/onboarding/Base";
import {computed, ref} from "vue";
import {useStore} from "vuex";
import router from "@/router";
import Autocomplete from 'primevue/autocomplete';
import {useConfirm} from "primevue/useconfirm";
import businessUtils from "@/utils/Business";
import conversationUtils from "@/utils/Conversation";
import {useI18n} from "vue-i18n";
import {onAuthStateChanged} from "firebase/auth";
import {auth} from "@/firebase/config";

export default {
  components: {OnboardingBase, Autocomplete},
  name: "OnboardingMakeATestCall",
  setup: function () {
    const store = useStore();
    const user = computed(() => store.state.user)
    const confirm = useConfirm();
    const onboardingData = computed(() => store.state.onboardingData)
    const { t, tm } = useI18n()
    const isWebView = computed(() => store.state.isWebview)
    const osName = computed(() => store.state.webviewOsName)

    return {
      t,
      tm,
      store,
      user,
      router,
      confirm,
      isWebView,
      osName,
      onboardingData
    }
  },
  data: function() {
    return {
      showSpinner: false,
      assistantHasBeenTested: false,
      modalDialogVisible: ref(false),

      modalDialogTitle: null,
      modalDialogIconClass: null,
      modalDialogSubTitle: null,
      modalDialogBodyMessage: null,
      modalDialogLeftButtonLabel: null,
      modalDialogRightButtonLabel: null,
      modalDialogLeftRedirectComponentName: null,
      modalDialogRightRedirectComponentName: null,

      modalDialogNoTestCallMessage: false,
    }
  },
  methods: {
    resetErrorMessage(redirectComponent) {
      console.debug("ResetErrorMessage: ", redirectComponent);
      this.modalDialogVisible = false ;
      this.modalDialogTitle = null ;
      this.modalDialogSubTitle = null ;
      this.modalDialogBodyMessage = null ;
      this.modalDialogLeftButtonLabel = null ;
      this.modalDialogRightButtonLabel = null ;
      this.modalDialogLeftRedirectComponentName = null ;
      this.modalDialogRightRedirectComponentName = null ;
      this.modalDialogNoTestCallMessage = false ;
      this.modalDialogIconClass = 'error-icon' ;
      this.router.push({
        name: redirectComponent
      });
    },
    retrieveLastConversation(businessId) {
      const convQuery = {
        businessId: businessId,
        lightweight: false,
        from: 0,
        size: 1
      }
      return conversationUtils.conversations(this.user, convQuery).then((conv) => {
        return conv;
      }).catch((error) => {
        console.debug("Error retrieving conversation", error.response);
        return {};
      }) ;
    },
    skipTest() {
      const self = this;
      if(self.osName === 'ios') {
        window.flutter_inappwebview.callHandler('onMrCallWebCallback', 'exit');
      } else {
        self.router.push({
          name: "OnboardingChoosePlan"
        });
      }
    },
    checkIfTested() {
      const self = this;
      this.showSpinner = true;
      const businessId = this.onboardingData.business.businessId ;
      businessUtils.getBusiness(this.store, this.user, businessId).then(async (item) => {
        console.debug("Business: ", item);
        this.assistantHasBeenTested = item && item.tested && item.tested.replace(" ", "T") !== "1970-01-01T00:00:00";
        self.showSpinner = false;
        let lastConversation = undefined ;
        if (self.assistantHasBeenTested) {
          //retrieve the last notification
          let counter = 0 ;
          self.showSpinner = true ;
          while(lastConversation === undefined && counter < 30) {
            const retrievedConv = await self.retrieveLastConversation(businessId);
            if(retrievedConv.totalHits > 0) {
              const item = Array.from(retrievedConv.hits)[0][1];
              if(item.audio) {
                lastConversation = item ;
              }
            } else {
              await new Promise(r => setTimeout(r, 2000));
            }
            counter += 1 ;
          }
          self.showSpinner = false ;
          if(lastConversation) {
            self.onboardingData.lastConversation = lastConversation ;
            self.router.push({
              name: "OnboardingNotificationPreview"
            });
          }
        }

        if(! self.assistantHasBeenTested || ! lastConversation) {
          // show error popup
          self.modalDialogIconClass = 'error-icon';
          self.modalDialogTitle = self.tm('views.onboarding.makeatestcall.modalDialogNoTestCallTitle');
          self.modalDialogSubTitle = self.t('views.onboarding.makeatestcall.modalDialogNoTestCallSubtitle');
          self.modalDialogBodyMessage = null;
          self.modalDialogLeftButtonLabel = self.t('views.onboarding.makeatestcall.modalDialogNoTestCallLeftButtonLabel');
          self.modalDialogRightButtonLabel = self.t('views.onboarding.makeatestcall.modalDialogNoTestCallRightButtonLabel');
          self.modalDialogLeftRedirectComponentName = null;
          self.modalDialogRightRedirectComponentName = "CallBooking";
          self.modalDialogNoTestCallMessage = true;
          self.modalDialogVisible = true;
        }
      }).catch((error) => {
        console.debug("Error retrieving business", error);
        self.showSpinner = false ;
      });
    },
    testNumber() {
      return this.onboardingData.business?.variables['ASSISTANT_LANG_SPECIFIC_NUMBER'] ;
    },
    testServiceNumber() {
      return this.onboardingData.business?.serviceNumber?.replace(/#.*$/, '') ;
    },
    testBusinessNumber() {
      return this.onboardingData.business?.businessPhoneNumber;
    },
    async initializePage() {
      const businessId = this.$route.query?.id;
      if(businessId) {
        await businessUtils.getBusiness(this.store, this.user, businessId).then((item) => {
          console.debug("Business: ", item);
          this.store.state.onboardingData = {
            business: item,
            onboardingRecover: true
          }
        })
      }
    }
  },
  async mounted() {
    const self = this;
    self.onboardingData.lastConversation = undefined ;
    if(! self.store.state.user) {
      onAuthStateChanged(auth, (user) => {
        if (user && user.emailVerified && !user.isAnonymous) {
          console.debug("UserStateChanged:", user)
          self.initializePage();
        }
      })
    } else {
      await self.initializePage();
    }
  },
  created() {
    console.log("MakeATestCall Called with: ", this.onboardingData);
  }
}
</script>

<style scoped lang="less">
@import '../../assets/style/colors';
@import '../../assets/style/components/templates/onboarding';
@import '../../assets/style/components/templates/onboarding_modal_dialog';

#makeatestcall-icon {
  background: url('../../assets/images/mrcall/icons/make_a_test_call.svg') no-repeat;
  width: 5em;
  height: 5em;
  margin: 0 auto 0 auto;
}

.phone-number-box {
  display: flex;
  flex-direction: row;
  background: @mrcall_background;
  margin: auto;
  padding: 1em;

  .phone-icon {
    background: url('../../assets/images/mrcall/icons/phone_icon.svg') no-repeat;
    width: 1.5em;
    height: 1.5em;
    margin: 0 1em 0 auto;
  }

  .phone-number {
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1.5em;
    line-height: 1em;
  }
}

.inputboxtitle {
  margin: 0 auto 0 auto;
}

.inputboxsubtitle {
  margin: 0 auto 0 auto;
}

.modal_dialog_no_testcall_title {
  margin: 0 0 1em 0 ;
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.1em;
}

ul {
  display: block;
  li {
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.8em;
    margin: 1em 0 1em 0;
  }
}
</style>
