<template>
  <Button 
    class="signin-signup-button-text" 
    type="button"
    @click="handleGoogleLogin"
    :label="$t('components.templates.sign.signup.signupWithGoogleButtonLabel')"
    :icon="'pi pi-google'"
  />
</template>

<script>
import { getAuth, signInWithCredential, GoogleAuthProvider } from 'firebase/auth'
import { useRouter } from 'vue-router'
import Button from 'primevue/button'
import axios from "axios"
import { OAuthHelper } from '@/utils/OAuth'
import store from '@/store'
import { PKCEUtils } from '@/utils/PKCE'

export default {
  name: 'GoogleSignIn',
  components: {
    Button
  },
  setup() {
    const router = useRouter()
    const auth = getAuth()

    // Add a function to wait for Google Identity Services
    const waitForGsi = (maxAttempts = 10) => {
      return new Promise((resolve, reject) => {
        let attempts = 0;
        const check = () => {
          attempts++;
          if (window.google?.accounts?.oauth2) {
            resolve(window.google.accounts.oauth2);
          } else if (attempts >= maxAttempts) {
            reject(new Error('Google Identity Services failed to load'));
          } else {
            setTimeout(check, 100); // Try again in 100ms
          }
        };
        check();
      });
    };

    const handleGoogleLogin = async () => {
      try {
        console.debug('Initiating Google OAuth flow with PKCE...')
        
        const codeVerifier = PKCEUtils.generateRandomString(128)
        localStorage.setItem('codeVerifier', codeVerifier)
        const codeChallenge = await PKCEUtils.generateCodeChallenge(codeVerifier)

        const authUrl = new URL('https://accounts.google.com/o/oauth2/v2/auth')
        authUrl.searchParams.set('client_id', process.env.VUE_APP_GOOGLE_CLIENT_ID)
        authUrl.searchParams.set('redirect_uri', process.env.VUE_APP_GOOGLE_REDIRECT_URI)
        authUrl.searchParams.set('response_type', 'code')
        authUrl.searchParams.set('scope', 'email profile')
        authUrl.searchParams.set('access_type', 'offline')
        authUrl.searchParams.set('prompt', 'consent')
        authUrl.searchParams.set('code_challenge', codeChallenge)
        authUrl.searchParams.set('code_challenge_method', 'S256')
        authUrl.searchParams.set('include_granted_scopes', 'true')

        window.location.href = authUrl.toString()
      } catch (error) {
        console.error('Google sign in error:', error)
      }
    }

    return {
      handleGoogleLogin
    }
  }
}
</script> 