// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.connect-calendar[data-v-25063448] {
  text-align: center;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
h2[data-v-25063448] {
  color: var(--primary-color);
  margin-bottom: 1rem;
}
p[data-v-25063448] {
  margin-bottom: 1.5rem;
  color: #666;
}
.loading[data-v-25063448] {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}
.error-message[data-v-25063448] {
  color: var(--red-500);
  margin-top: 0.5rem;
  display: block;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
