import axios from 'axios'

export const GOOGLE_SCOPES = [
  'openid',
  'profile',
  'email'
]

export const OAuthHelper = {
  async getGoogleCredentials(user) {
    try {
      const headers = {
        "Content-type": "application/json; charset=UTF-8",
        "auth": user.accessToken
      }
      const url = process.env.VUE_APP_STARCHAT_URL + "/mrcall/v1/talkmeapp0/crm/customer/registry?id=" + user.uid
      const response = await axios.get(url, { headers })
      return response.data?.data?.oauth?.GOOGLE || null
    } catch (error) {
      console.error('Failed to get Google credentials:', error)
      return null
    }
  },

  async updateGoogleCredentials(user, credentials) {
    try {
      if (!credentials?.accessToken) {
        throw new Error('Invalid OAuth credentials: missing access token')
      }
  
      const headers = {
        "Content-type": "application/json; charset=UTF-8",
        "auth": user.accessToken
      }
  
      // Store the raw credentials (or wrap them as needed by your backend)
      const customerData = {
        oauth: {
          GOOGLE: credentials
        }
      }
  
      const url = process.env.VUE_APP_STARCHAT_URL + "/mrcall/v1/talkmeapp0/crm/customer/registry"
        
      await axios.put(url, customerData, { headers })
  
      return customerData
    } catch (error) {
      console.error('Failed to store OAuth credentials:', error)
      console.error('Error details:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
        config: error.config
      })
      throw error
    }
  },

  isTokenExpired(credentials) {
    if (!credentials?.expiresAt) return true
    // Add 5 min buffer
    return Date.now() > (credentials.expiresAt - 300000)
  },

  async revokeAccess(user) {
    try {
      const credentials = await this.getGoogleCredentials(user)
      if (credentials?.accessToken) {
        await axios.post(
          `https://oauth2.googleapis.com/revoke?token=${credentials.accessToken}`
        )
      }
    } catch (error) {
      console.error('Failed to revoke Google access:', error)
      throw new Error('Failed to revoke Google access')
    }
  },

  async getGoogleCalendarCredentials(user) {
    try {
      const headers = {
        "Content-type": "application/json; charset=UTF-8",
        "auth": user.accessToken
      }
      const url = process.env.VUE_APP_STARCHAT_URL + "/mrcall/v1/talkmeapp0/crm/customer/registry?id=" + user.uid
      const response = await axios.get(url, { headers })
      return response.data?.data?.oauth?.GOOGLE_CALENDAR || null
    } catch (error) {
      console.error('Failed to get Google Calendar credentials:', error)
      return null
    }
  },

  async updateGoogleCalendarCredentials(user, credentials) {
    try {
      if (!credentials?.accessToken) {
        throw new Error('Invalid OAuth credentials: missing access token')
      }
  
      const headers = {
        "Content-type": "application/json; charset=UTF-8",
        "auth": user.accessToken
      }
  
      const customerData = {
        oauth: {
          GOOGLE_CALENDAR: credentials
        }
      }
  
      const url = process.env.VUE_APP_STARCHAT_URL + "/mrcall/v1/talkmeapp0/crm/customer/registry"
      await axios.put(url, customerData, { headers })
  
      return customerData
    } catch (error) {
      console.error('Failed to store calendar credentials:', error)
      throw error
    }
  },

  async revokeAllGoogleAccess(user) {
    try {
      // Revoke main OAuth credentials
      const credentials = await this.getGoogleCredentials(user)
      if (credentials?.accessToken) {
        await axios.post(`https://oauth2.googleapis.com/revoke?token=${credentials.accessToken}`)
      }

      // Revoke calendar credentials
      const calendarCredentials = await this.getGoogleCalendarCredentials(user)
      if (calendarCredentials?.accessToken) {
        await axios.post(`https://oauth2.googleapis.com/revoke?token=${calendarCredentials.accessToken}`)
      }

      // Clear stored credentials
      await this.updateGoogleCredentials(user, null)
      await this.updateGoogleCalendarCredentials(user, null)
    } catch (error) {
      console.error('Failed to revoke Google access:', error)
      throw new Error('Failed to revoke Google access')
    }
  }
} 