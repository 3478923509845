import { onAuthStateChanged } from 'firebase/auth'
import { auth } from './config'
import store from '../store'
import router from '@/router'

// Setup auth state listener
export function setupAuthListener() {
  onAuthStateChanged(auth, (user) => {
    store.commit('setUser', user)
    store.commit('setAuthIsReady', true)
  })
}

// Function to proactively refresh token
export async function refreshAuthToken() {
  const user = auth.currentUser
  if (user) {
    try {
      await user.getIdToken(true)
      return true
    } catch (error) {
      console.error("Failed to refresh token:", error)
      return false
    }
  }
  return false
}

export { auth } 