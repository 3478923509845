// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* PRINCIPALS */
/* SECONDARY */
/* ACCESSORY */
/* TEMPLATES */
.terms-privacy[data-v-3f144ca0] {
  font-size: 0.9em;
  color: #666;
}
.terms-privacy .link[data-v-3f144ca0] {
  text-decoration: none;
  color: #0068FF;
}
.terms-privacy .link[data-v-3f144ca0]:hover {
  text-decoration: underline;
}
.mt-3[data-v-3f144ca0] {
  margin-top: 1rem;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
