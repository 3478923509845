<template>
  <OnboardingBase>
    <template #spinner>
      <ProgressSpinner
          v-show="showSpinner"
          style="position: fixed; top: 50%; transform: translate(0%, -50%); width: 25%; height: 20%; z-index: 100;"
          strokeWidth="4"
          :pt="{
            spinner: { style: { animationDuration: '2s' } },
            circle: { style: { stroke: '#0068FF', strokeWidth: 3, animation: 'auto' } }
          }"
          fill="transparent"
          animationDuration="2.5s" aria-label="ProgressSpinner" />
    </template>
    <template #title>{{ $t('views.onboarding.searchbusinessinfo.titleText') }}</template>
    <template #subtitle>{{ showSpinner ? $t('views.onboarding.searchbusinessinfo.subtitleTextSearch') : $t('views.onboarding.searchbusinessinfo.subtitleText') }}</template>
    <template #content>
      <div class="item" v-if="!showSpinner">
        <div class="inputboxtitle">
          {{$t('views.onboarding.searchbusinessinfo.businessInformation')}}
        </div>
        <Textarea v-model="businessInformations"
                  class="w-full"
        />
        <!--        {{businessInformations}}-->
        <!--        {{onboardingData['selectedGMapsResult']}}-->
        <div class="inputboxsubtitle" style="padding: 1.5em 0 0 1em;">
          <a href="#" @click="submitSkipInfo()">{{$t('views.onboarding.searchbusinessinfo.skipInfo')}}</a>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="button-left">
        <Button :label="$t('views.onboarding.searchbusinessinfo.moveBackward')" @click="router.push({name: 'OnboardingSearchBusiness'})" class="p-button-text md:w-auto py-3 w-full p-button-left"/>
      </div>
      <div class="button-left">
        <Button :disabled="showSpinner" :loading="showSpinner" :label="showSpinner ? $t('views.onboarding.searchbusinessinfo.loading') : $t('views.onboarding.searchbusinessinfo.reload')" @click="searchBusinessInformation()" class="p-button-text md:w-auto py-3 w-full p-button-left"/>
      </div>
      <div class="button-right">
        <Button :disabled="showSpinner" :label="$t('views.onboarding.searchbusinessinfo.moveForward')" @click="submit()" class="p-button-text md:w-auto py-3 w-full"/>
      </div>
    </template>
  </OnboardingBase>
</template>

<script>
import OnboardingBase from "@/components/templates/onboarding/Base";
import {computed, ref} from "vue";
import {useStore} from "vuex";
import router from "@/router";
import Autocomplete from 'primevue/autocomplete';
import axios from "axios";
import {useConfirm} from "primevue/useconfirm";
import {useI18n} from "vue-i18n";

export default {
  components: {OnboardingBase, Autocomplete},
  name: "OnboardingSearchBusinessInfo",
  setup: function () {
    const store = useStore();
    const user = computed(() => store.state.user)
    const confirm = useConfirm();
    return {
      store,
      user,
      router,
      confirm
    }
  },
  data: function() {
    const { t } = useI18n()
    const onboardingData = computed(() => this.store.state.onboardingData)
    return {
      showSpinner: false,
      t,
      submitted: false,
      onboardingData,
      businessInformations: ref()
    }
  },
  methods: {
    submitSkipInfo() {
      this.clearSelection();
      this.onboardingData.business.nickname = this.onboardingData.business.name;
      this.router.push({
        name: "OnboardingConfigureAssistant"
      });
    },
    submit() {
      this.submitted = true ;
      if(this.businessInformations) {
        this.onboardingData.business.variables.ABOUT_BUSINESS_INFORMATION = this.businessInformations;
        this.router.push({
          name: "OnboardingConfigureAssistant"
        });
      } else {
        this.router.push({
          name: "OnboardingConfigureAssistant"
        });
      }
    },
    clearSelection() {
      this.businessInformations = ref();
      delete this.onboardingData['businessInformations'];
    },
    searchBusinessInformation() {
      const self = this ;
      self.clearSelection();
      this.$gtag.event("onboarding_searchbusinessinfo", {
        'user_email': self.user.email,
      })
      const headers = {
        "Content-type": "application/json; charset=UTF-8",
        "auth": this.user.accessToken
      };
      const request = {
        "company name": this.onboardingData.selectedGMapsResult.name,
        "company address": this.onboardingData.selectedGMapsResult.formattedAddress,
        "company url": this.onboardingData.selectedGMapsResult.website,
        "#language": this.onboardingData.business.languageCountry
      }
      console.log("queryRequest: ", request)
      this.showSpinner = true
      axios.post(process.env.VUE_APP_STARCHAT_URL + "/mrcall/v1/talkmeapp0/atom/search_business_data_onb",
          request,
          {
            headers: headers
          }
      ).then((response) => {
        console.debug("Search business info Response:", response)
        this.showSpinner = false
        if( response.data) {
          self.businessInformations = response.data?.informations
        }
        console.debug("Result: ", self.businessInformations)
      }).catch((error) => {
        self.businessInformations = ref()
        this.showSpinner = false
        console.error(error);
        if(error.response.status === 401) {
          store.dispatch('logout')
          router.replace('/login')
        }
      })
    }
  },
  mounted() {
  },
  created() {
    console.log("OnboardingSearchBusinessInfo Called with: ", this.onboardingData);
    if(! this.onboardingData.business) {
      this.onboardingData.business = {
        variables: {}
      }
    }
    if(! this.onboardingData.business.languageCountry) {
      this.router.push({
        name: "OnboardingLanguage"
      });
    }
    if(this.onboardingData.selectedGMapsResult) {
      this.searchBusinessInformation()
    }
  }
}
</script>

<style scoped lang="less">
@import '../../assets/style/colors';
@import '../../assets/style/components/templates/onboarding';
@import '../../assets/style/components/templates/onboarding_modal_dialog';

.flex-grow {
  flex: 1;
}

.mc-autocomplete-item {
  background: transparent;
  flex-direction: column;
}

.p-dialog-footer {
  display: flex ;
}
</style>