<template>
  <OnboardingBase>
    <template #title>{{ $t('views.onboarding.searchbusiness.titleText') }}</template>
    <template #subtitle>{{ $t('views.onboarding.searchbusiness.subtitleText') }}</template>
    <template #content>
      <Dialog v-model:visible="warningDialogVisible"
              :dismissableMask="true"
              :closable="false"
              modal>
        <div class="modal_dialog_content">
          <div class="modal_dialog_icon warning-icon">
          </div>
          <div class="modal_dialog_title">
            {{$t('views.onboarding.searchbusiness.noBusinessSelectedTitle')}}
          </div>
          <div class="modal_dialog_message">
            {{$t('views.onboarding.searchbusiness.noBusinessSelectedMessage')}}
          </div>
        </div>
        <template #footer>
          <div class="modal-dialog-footer">
            <div class="button-left">
              <Button :label="$t('views.onboarding.searchbusiness.moveBackward')" @click="warningDialogVisible = false" class="p-button-text md:w-auto py-3 w-full p-button-left"/>
            </div>
            <div class="button-right">
              <Button :label="$t('views.onboarding.searchbusiness.continueButtonLabel')" @click="submitNoCompany()" class="p-button-text md:w-auto py-3 w-full"/>
            </div>
          </div>
        </template>
      </Dialog>
      <ConfirmDialog group="business-dialog-templating">
        <template #message="slotProps">
          <div id="dialogContent">
            <div id="modal_dialog_icon">
            </div>
            <div id="modal_dialog_title">
              {{$t('views.onboarding.searchbusiness.noBusinessSelectedTitle')}}
            </div>
            <div class="flex p-4">
              <i :class="slotProps.message.icon" style="font-size: 1.5rem"></i>
              <p class="pl-2">{{ slotProps.message.message }}</p>
            </div>
          </div>
        </template>
      </ConfirmDialog>
      <div class="item">
        <div class="inputboxtitle">
          {{$t('views.onboarding.searchbusiness.searchYourBusiness')}}
        </div>
        <Autocomplete v-model="searchString"
                      inputClass="w-full"
                      :suggestions="items"
                      :completeOnFocus="false"
                      dataKey="placeId"
                      optionLabel="name"
                      dropdownMode="blank"
                      @item-select="selected"
                      @complete="searchBusiness"
        >
          <template #option="slotProps">
            <div v-if="slotProps.option.isValid" class="mc-autocomplete-item flex align-options-center">
              <div class="inputboxtitle">{{ slotProps.option.name }}</div>
              <div class="inputboxsubtitle">{{ slotProps.option.formattedAddress }}</div>
              <div class="inputboxsubtitle" v-if="slotProps.option.phoneNumber">{{ slotProps.option.phoneNumber }}</div>
            </div>
            <div v-else class="mc-autocomplete-item flex align-options-center">
              <div class="inputboxtitle">{{ slotProps.option.notFoundTitle }}</div>
              <div class="inputboxsubtitle">{{ slotProps.option.notFoundSubtitle }}</div>
            </div>
          </template>
        </Autocomplete>
        <div class="inputboxerror" v-if="noSearchInputTextProvided && submitted">
          {{$t('views.onboarding.searchbusiness.searchParametersEmptyMessage')}}
        </div>
        <div class="inputboxsubtitle" style="padding: 1.5em 0 0 1em;">
          <a href="#" @click="submitNoCompany()">{{$t('views.onboarding.searchbusiness.doNotOwnABusiness')}}</a>
        </div>
        <div id="selection_item">
          <div id="selected_result" v-if="selectedGMapsResult">
            <div id="left-size">
              <div class="inputboxtitle">{{ selectedGMapsResult.name }}</div>
              <div class="inputboxsubtitle">{{ selectedGMapsResult.formattedAddress }}</div>
              <div class="inputboxsubtitle" v-if="selectedGMapsResult.phoneNumber">{{ selectedGMapsResult.phoneNumber }}</div>
            </div>
            <div id="right-side">
              <Button :label="$t('views.onboarding.searchbusiness.removeSelection')" @click="clearSelection()" class="p-button-text md:w-auto py-3 w-full p-button-left"/>
            </div>
          </div>
        </div>
      </div>

    </template>
    <template #footer>
      <div class="button-left">
        <Button :label="$t('views.onboarding.searchbusiness.moveBackward')" @click="router.push({name: 'OnboardingNamePhone'})" class="p-button-text md:w-auto py-3 w-full p-button-left"/>
      </div>
      <div class="button-right">
        <Button :label="$t('views.onboarding.searchbusiness.moveForward')" @click="submit()" class="p-button-text md:w-auto py-3 w-full"/>
      </div>
    </template>
  </OnboardingBase>
</template>

<script>
import OnboardingBase from "@/components/templates/onboarding/Base";
import {computed, ref} from "vue";
import {useStore} from "vuex";
import router from "@/router";
import Autocomplete from 'primevue/autocomplete';
import axios from "axios";
import {useConfirm} from "primevue/useconfirm";
import {useI18n} from "vue-i18n";

export default {
  components: {OnboardingBase, Autocomplete},
  name: "OnboardingSearchBusiness",
  setup: function () {
    const store = useStore();
    const user = computed(() => store.state.user)
    const confirm = useConfirm();
    return {
      store,
      user,
      router,
      confirm
    }
  },
  data: function() {
    const { t } = useI18n()
    const onboardingData = computed(() => this.store.state.onboardingData)
    return {
      t,
      warningDialogVisible: ref(false),
      noSearchInputTextProvided: false,
      submitted: false,
      onboardingData,
      searchString: "",
      items: ref(),
      selectedGMapsResult: ref()
    }
  },
  methods: {
    showNoBusinessDialog() {
      this.confirm.require({
        group: 'business-dialog-templating',
        message: this.t('views.onboarding.searchbusiness.noBusinessSelectedMessage'),
        acceptLabel: this.t('views.onboarding.searchbusiness.continueButtonLabel'),
        rejectLabel: this.t('views.onboarding.searchbusiness.moveBackward'),
        //acceptIcon: 'pi pi-check',
        //rejectIcon: 'pi pi-times',
        acceptClass: "modal-onboarding-button",
        rejectClass: "modal-onboarding-button-left",
        defaultFocus: "reject",
        accept: () => {
          this.submitNoCompany();
        },
        reject: () => {
        }
      });
    },
    submitNoCompany() {
      this.clearSelection();
      this.onboardingData.business.nickname = this.onboardingData.business.name;
      this.router.push({
        name: "OnboardingConfigureAssistant"
      });
    },
    submit() {
      this.submitted = true ;
      if(this.selectedGMapsResult) {
        this.onboardingData.selectedGMapsResult = this.selectedGMapsResult;
        this.router.push({
          name: "OnboardingSearchBusinessInfo"
        });
      } else {
        this.warningDialogVisible = true;
        //this.showNoBusinessDialog();
      }
      this.noSearchInputTextProvided = !this.searchString;
    },
    clearSelection() {
      this.selectedGMapsResult = ref();
      delete this.onboardingData['selectedGMapsResult'];
      this.onboardingData['welcomeMessage'] = "" ;
      this.onboardingData.business.companyName = "" ;
      this.onboardingData.business.nickname = "" ;
      this.onboardingData.business.address = "" ;
      this.onboardingData.business.variables.SYNC_GOOGLE_BUSINESS = false ;
      this.onboardingData.business.variables.GOOGLE_PLACE_ID = "";
    },
    selected(event) {
      console.log("Selected: ", event);
      const self = this;
      this.clearSelection() ;
      if(event.value.isValid) {
        this.selectedGMapsResult = event.value ;
        this.$gtag.event("onboarding_searchbusiness_select", {
          'user_email': self.user.email,
          'companyName': this.onboardingData.business.companyName,
          'nickname': this.onboardingData.business.nickname
        })
        this.onboardingData.business.companyName = this.selectedGMapsResult.name ;
        this.onboardingData.business.nickname = this.selectedGMapsResult.name ;
        this.onboardingData.business.address = this.selectedGMapsResult.formattedAddress ;
        this.onboardingData.business.variables.SYNC_GOOGLE_BUSINESS = true;
        this.onboardingData.business.variables.GOOGLE_PLACE_ID = this.selectedGMapsResult.placeId;
      }
    },
    searchBusiness(event) {
      const self = this ;
      this.$gtag.event("onboarding_searchbusiness", {
        'user_email': self.user.email,
        'query': event.query
      })
      const headers = {
        "Content-type": "application/json; charset=UTF-8",
        "auth": this.user.accessToken
      };
      const request = {
        query: event.query,
        language: this.onboardingData.business.languageCountry
      }
      console.log("queryRequest: ", request)
      this.showProgressBar = true
      axios.post(process.env.VUE_APP_STARCHAT_URL + "/mrcall/v1/talkmeapp0/place/search",
          request,
          {
            headers: headers
          }
      ).then((response) => {
        console.debug("Search Response:", response)
        this.showProgressBar = false
        const items = []
        const responseData = response.data
        if(responseData) {
          if(responseData.length > 0) {
            responseData.forEach(function (item) {
              items.push({
                isValid: true,
                placeId: item.main.place.placeId,
                name: item.main.place.name,
                formattedAddress: item.main.place.formattedAddress,
                types: item.main.place.types,
                businessStatus: item.main.place.businessStatus,
                phoneNumber: item.main.details.internationalPhoneNumber,
                website: item.main.details.website
              });
            })
          } else {
            items.push({
              isValid: false,
              placeId: "invalid",
              name: self.searchString,
              notFoundTitle: `${self.yourSearchOf} "${self.searchString}" ${self.didNotProduceResults}.`,
            });
          }
        }
        self.items = items
        console.debug("Result: ", self.items)
      }).catch((error) => {
        self.items = ref()
        this.showProgressBar = false
        console.error(error);
        if(error.response.status === 401) {
          store.dispatch('logout')
          router.replace('/login')
        }
      })
    }
  },
  mounted() {
  },
  created() {
    console.log("SearchBusiness Called with: ", this.onboardingData);
    if(! this.onboardingData.business) {
      this.onboardingData.business = {
        variables: {}
      }
    } else if(this.onboardingData.selectedGMapsResult) {
      this.selectedGMapsResult = this.onboardingData.selectedGMapsResult;
    }
    if(! this.onboardingData.business.languageCountry) {
      this.router.push({
        name: "OnboardingLanguage"
      });
    }
  }
}
</script>

<style scoped lang="less">
@import '../../assets/style/colors';
@import '../../assets/style/components/templates/onboarding';
@import '../../assets/style/components/templates/onboarding_modal_dialog';

.mc-autocomplete-item {
  background: transparent;
  flex-direction: column;
}

#selection_item {
  width: 100%;
  padding: 1em 0 0 0;
}

.p-dialog-footer {
  display: flex ;
}
#selected_result {
  .inputboxtitle {
    padding: 0 ;
  }
  border: 1px;
  border-color: @mrcall_grey_text2;
  border-radius: 6px;
  border-style: solid;
  padding: 1em;

  display: flex;
  flex-direction: row;

  #left-size {
    display: flex;
    flex-direction: column;
    width: 65%;
  }

  #right-side {
    width: 35%;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-direction: column;
    .p-button {
      font-family: 'Inter',serif;
      font-style: normal;
      font-weight: 700;
      font-size: 0.8em;
      line-height: 1em;

      color: @mrcall_grey_text2;
      background: transparent;
      border: 1px solid @mrcall_grey_text2;
      padding: 0.5rem 1em;
      margin: 0.2em 0.2em 0.2em auto;
      border-radius: 33px;
    }

    .p-button-left.p-button:hover {
      background: @mrcall_grey_text2;
      color: @mrcall_white;
    }
  }
}
</style>