<template>
  <BlockUI :blocked="blocked" fullScreen>
    <div class="plans">
      <div style="width: 100% ; height: 100%; position: fixed; text-align: center;">
        <ProgressSpinner
            v-show="showSpinner"
            style=" margin: auto auto auto auto; width: 25%; height: 25%; z-index: 100;"
            strokeWidth="4"
            :pt="{
            spinner: { style: { animationDuration: '2s' } },
            circle: { style: { stroke: '#0068FF', strokeWidth: 3, animation: 'auto' } }
          }"
            fill="transparent"
            animationDuration="2.5s" aria-label="ProgressSpinner" />
      </div>
      <div class="plans-content">
        <div class="plans-heading">
          <div class="plans-title">
            {{t('views.onboarding.chooseplan.title')}}
          </div>
          <div class="plans-subtitle">
            {{t('views.onboarding.chooseplan.subtitle')}}
          </div>
          <!--
          <div class="plans-highlight">
            {{t('views.onboarding.chooseplan.textForFreeTrial')}} <a href="/businesses">{{t('views.onboarding.chooseplan.myAssistantLink')}}</a>
          </div>
          -->
        </div>
        <div class="plans-body">
          <Dialog v-model:visible="modalDialogVisible"
                  :dismissableMask="false"
                  :closable="false"
                  modal>
            <div class="modal_dialog_content">
              <div :class="'modal_dialog_icon' + ' ' + modalDialogIconClass">
              </div>
              <div class="modal_dialog_title">
                <div v-for="item in modalDialogTitle" :key="item">
                  {{item}}
                </div>
              </div>
              <div v-if="modalDialogSubTitle" class="modal_dialog_subtitle">
                {{modalDialogSubTitle}}
              </div>
              <div class="modal_dialog_message">
                <div v-for="item in modalDialogBodyMessage" :key="item" style="line-height: 1.5em;">
                  {{item}}
                </div>
              </div>
            </div>
            <template #footer>
              <div class="modal-dialog-footer">
                <div v-if="modalDialogLeftButtonLabel" class="button-left">
                  <Button :label="modalDialogLeftButtonLabel" @click="resetErrorMessage(modalDialogLeftRedirectComponentName)" class="p-button-text md:w-auto py-3 w-full p-button-left"/>
                </div>
                <div v-else class="button-left">
                </div>
                <div v-if="modalDialogRightButtonLabel" class="button-right">
                  <Button :label="modalDialogRightButtonLabel" @click="resetErrorMessage(modalDialogRightRedirectComponentName)" class="p-button-text md:w-auto py-3 w-full"/>
                </div>
              </div>
            </template>
          </Dialog>
          <div v-for="item in tm('views.onboarding.chooseplan.plans')" :key="item" class="plans-item">
            <div class="plan-item-title">
              {{item.title}}
            </div>
            <div class="plan-item-highlight">
              {{item.highlight}}
            </div>
            <div class="plan-item-pricing">
              <div class="plan-item-currency">
                {{item.pricing.currency}}
              </div>
              <div class="plan-item-number">
                {{item.pricing.number}}
              </div>
              <div class="plan-item-periodicity">
                {{item.pricing.periodicity}}
              </div>
            </div>
            <div class="plan-item-button">
              <Button :label="item.buttonLabel"
                      @click="paymentSession(item.id)"
                      class="py-2 w-full p-button-left"/>
            </div>
            <div class="plan-item-functionalities">
              <div class="plan-item-functionalities-title">
                {{item.functionalities.title}}
              </div>
              <div v-for="functionality in item.functionalities.items" :key="functionality" class="plan-item-functionality">
                <div class="check-icon"></div><div class="feature">{{functionality.value}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </BlockUI>
</template>

<script>

import {useStore} from "vuex";
import {computed, ref} from "vue";
import businessUtils from "@/utils/Business";
import stripeUtils from "@/utils/Stripe";
import router from "@/router";
import {onAuthStateChanged} from "firebase/auth";
import {auth} from "@/firebase/config";
import {useI18n} from "vue-i18n";

export default {
  name: "OnboardingChoosePlan",
  setup: function() {
    const store = useStore();
    const user = computed(() => store.state.user)
    const onboardingData = computed(() => store.state.onboardingData)
    const { t, tm } = useI18n()
    return {
      t,
      tm,
      store,
      user,
      onboardingData,
    }
  },
  data: function() {
    return {
      showSpinner: false,
      blocked: false,
      modalDialogVisible: ref(false),
      modalDialogTitle: null,
      modalDialogIconClass: 'error-icon',
      modalDialogSubTitle: null,
      modalDialogBodyMessage: null,
      modalDialogLeftButtonLabel: null,
      modalDialogRightButtonLabel: null,
      modalDialogLeftRedirectComponentName: null,
      modalDialogRightRedirectComponentName: null
    }
  },
  methods: {
    async paymentSession(template) {
      console.debug("Payment Session: ", this.onboardingData);
      this.showSpinner = true ;
      const self = this;
      const businessId = this.onboardingData.business.businessId ;
      const isWebview = this.store.state.isWebview ;
      const apple = false ;
      //const successUrl = process.env.VUE_APP_SERVICE_BASE_URL + process.env.VUE_APP_PUBLIC_PATH +
      //    "/success_payment?id=" + businessId + "&template=" + template + "&webview=" + isWebview ;
      //const successUrl = process.env.VUE_APP_SERVICE_BASE_URL + process.env.VUE_APP_PUBLIC_PATH +
      //    "/onboardingchoosedevice?id=" + businessId + "&webview=" + isWebview ;
      const successUrl = process.env.VUE_APP_SERVICE_BASE_URL + process.env.VUE_APP_PUBLIC_PATH +
          "/businesses" ;
      const cancelUrl =
          process.env.VUE_APP_SERVICE_BASE_URL + process.env.VUE_APP_PUBLIC_PATH +
          "/onboardingchooseplan?id=" + businessId ;
      let business = this.onboardingData.business ;
      business.template = template ;
      self.blocked = true ;

      const convertedBusiness = businessUtils.businessVariablesToSerializable(business)
      await businessUtils.chooseBusinessTemplate(this.user, convertedBusiness).then((updBusiness) => {
        self.showSpinner = false ;
        if(isWebview && apple) {
          self.blocked = true ;
        } else {
          return stripeUtils.createSession(this.user, template, businessId, successUrl, cancelUrl)
              .then((session) => {
                self.blocked = false ;
                window.location.href = session.url;
              })
        }
      }).catch((error) => {
        console.error(error);
        self.showSpinner = false ;
        self.blocked = false ;
        self.modalDialogTitle = self.tm('views.onboarding.chooseplan.errorDialogUnknownErrorTitle') ;
        self.modalDialogBodyMessage = self.tm('views.onboarding.chooseplan.errorDialogUnknownErrorBody') ;
        self.modalDialogRightButtonLabel = self.t('views.onboarding.chooseplan.unknownErrorBusinessErrorRightButtonLabel');
        self.modalDialogVisible = true ;
      })
    },
    resetErrorMessage(redirectComponent) {
      console.debug("ResetErrorMessage: ", redirectComponent);
      this.modalDialogVisible = false;
      this.modalDialogTitle = null;
      this.modalDialogSubTitle = null;
      this.modalDialogBodyMessage = null;
      this.modalDialogLeftButtonLabel = null;
      this.modalDialogRightButtonLabel = null;
      this.modalDialogLeftRedirectComponentName = null;
      this.modalDialogRightRedirectComponentName = null;
      this.modalDialogIconClass = 'error-icon';
      if(redirectComponent) {
        this.router.push({
          name: redirectComponent
        });
      }
    },
    initializePage() {
      const businessId = this.$route.query?.id;
      if (!this.onboardingData?.business?.languageCountry) {
        if(businessId) {
          businessUtils.getBusiness(this.store, this.user, businessId).then((item) => {
            console.debug("Business: ", item);
            this.store.state.onboardingData = {
              business: item
            }
          })
        } else {
          router.push({
            name: "OnboardingLanguage"
          });
        }
      }
    }
  },
  mounted() {
    const self = this;
    if(! self.store.state.user) {
      onAuthStateChanged(auth, (user) => {
        if (user && user.emailVerified && !user.isAnonymous) {
          console.debug("UserStateChanged:", user)
          self.initializePage();
        }
      })
    } else {
      self.initializePage();
    }
  },
  created() {
    console.log("SelectPlan Called with: ", this.onboardingData);
  }
}

</script>

<style scoped lang="less">
@import '../../assets/style/colors';
@import '../../assets/style/components/templates/configuration_items';
@import '../../assets/style/components/templates/onboarding_modal_dialog';

.plans {
  background: @mrcall_white;
  width: 100% ;
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1em;
  line-height: 1.0em;

  .plans-content {
    margin: auto;
    max-width: fit-content;
    padding: 0 1em 1em 1em;
    .plans-heading {
      padding: 1em 0 2em 0;
      text-align: center;
      .plans-title {
        color: @mrcall_grey_text;
        font-weight: 700;
        font-size: 1.7em;
        margin: auto auto auto auto;
        line-height: 1.2em;
      }

      .plans-subtitle {
        color: @mrcall_grey_text;
        font-size: 0.8em;
        font-weight: 400;
        margin: auto auto 0.5em auto;
      }

      .plans-highlight {
        color: @mrcall_blue;
        margin: 2em auto 0.5em auto;
      }
    }

    .plans-body {
      display: flex;

      .plans-item {
        border: solid;
        border-color: @mrcall_light_grey_1;
        border-radius: 6px;
        border-width: thin;
        padding: 1.5em 1em 0.5em 1em;
        overflow: visible;

        .plan-item-title {
          color: @mrcall_grey_text;
          font-size: 1.5em;
          margin-bottom: 0.5em;
        }

        .plan-item-highlight {
          color: @mrcall_blue;
          font-size: 0.8em;
        }

        .plan-item-pricing {
          color: @mrcall_grey_text;
          display: flex;
          margin: 1em 0 1em auto;
          padding: 0 0 0 0;
          flex-direction: row;
          .plan-item-currency {
            font-size: 1.8em;
            margin: auto 0 0 0;
          }

          .plan-item-number {
            font-size: 1.8em;
            margin: auto 0.2em 0 0;
          }

          .plan-item-periodicity {
            font-size: 0.8em;
            margin: auto 0 0 0;
          }
        }

        .plan-item-button {
          .p-button {
            font-family: 'Inter', serif;
            font-style: normal;
            font-weight: 700;
            font-size: 0.8em;
            line-height: 1em;
          }

          margin: 0 0 1em 0;
        }

        .plan-item-functionalities {
          color: @mrcall_grey_text;
          .plan-item-functionalities-title {
            font-size: 1.2em;
            margin-bottom: 1em;
          }
          .plan-item-functionality {
            display: flex ;
            flex-direction: row;
            font-size: 1.0em;
            margin-bottom: 0.5em;
            .check-icon {
              margin: 0.1em;
              background: url(../../assets/images/mrcall/icons/check_icon.svg) no-repeat;
              width: 1em;
              height: 1em;
              margin-right: 0.8em;
            }
            .feature {
              width: 100%;
            }
          }
        }

      }

      @media screen and (max-width: 640px) {
        flex-direction: column;
        .plans-item {
          margin: 0 0.5em 0.5em 0.5em;
        }
      }
      @media screen and (min-width: 640px) {
        flex-direction: row;
        .plans-item {
          max-width: 18em;
          margin: 0.5em ;
        }
      }
    }
  }
}

</style>