<script setup>
import Sign from "@/components/templates/sign/Sign";
import Signin from "@/components/templates/sign/Signin";
</script>

<template>
  <div>
    <Sign>
      <template #title>
        <div id="title">{{$t('views.sign.signin.titleText')}}</div>
      </template>
      <template #content>
        <Signin></Signin>
      </template>
    </Sign>
  </div>
</template>

<style scoped lang="less">
@import '../../assets/style/colors';
</style>