<script>
import Sign from '@/components/templates/sign/Sign.vue'
import Signin from '@/components/templates/sign/Signin.vue'
import { useI18n } from 'vue-i18n'

export default {
  components: {
    Sign,
    Signin
  },
  setup() {
    const { t } = useI18n()
    return { t }
  }
}
</script>

<template>
  <Sign>
    <template #title>
      {{ t('views.sign.signin.titleText') }}
    </template>
    
    <template #content>
      <Signin />
    </template>

    <template #signin-signup-alternative>
      <div data-v-2a7df62a class="text-center">
        <span data-v-2a7df62a class="companion-text">{{ t('components.templates.sign.signin.signupLinkCompanionText') }}</span>
        <span data-v-2a7df62a class="companion-text">&nbsp;</span>
        <a data-v-2a7df62a href="/signup" class="link">{{ t('components.templates.sign.signin.signupButtonLabel') }}</a>
      </div>
    </template>
  </Sign>
</template>

<style scoped>
.text-center {
  width: 268.73px;
  height: 19.2px;
}
</style>